@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');

#root {
    /* Types */
    --normal-type: #9da0aa;
    --fire-type: #fd7d24;
    --water-type: #4a90da;
    --electric-type: #eed535;
    --grass-type: #62b957;
    --ice-type: #61cec0;
    --fighting-type: #d04164;
    --poison-type: #a552cc;
    --ground-type: #dd7748;
    --flying-type: #748fc9;
    --psychic-type: #ea5d60;
    --bug-type: #8cb230;
    --rock-type: #baab82;
    --ghost-type: #556aae;
    --dragon-type: #0f6ac0;
    --dark-type: #58575f;
    --steel-type: #417d9a;
    --fairy-type: #ed6ec7;

    /* Type backgrounds */
    --background-normal-type: #b5b9c4;
    --background-fire-type: #ffa756;
    --background-water-type: #58abf6;
    --background-electric-type: #f2cb55;
    --background-grass-type: #8bbe8a;
    --background-ice-type: #91d8df;
    --background-fighting-type: #eb4971;
    --background-poison-type: #9f6e97;
    --background-ground-type: #f78551;
    --background-flying-type: #83a2e3;
    --background-psychic-type: #ff6568;
    --background-bug-type: #8bd674;
    --background-rock-type: #d4c294;
    --background-ghost-type: #8571be;
    --background-dragon-type: #7383b9;
    --background-dark-type: #6f6e78;
    --background-steel-type: #4c91b2;
    --background-fairy-type: #eba8c3;

    /* Backgrounds */
    --background-white: #ffffff;
    --background-default-input: #f2f2f2;
    --background-pressed-input: #e2e2e2;
    --background-modal: rgba(0, 0, 0, 0.25);

    /* Gradients */
    --gradient-vector: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    --gradient-vector-grey: linear-gradient(100.59deg, #e5e5e5 0%, rgba(245, 245, 245, 0) 100%);
    --gradient-vector-white: linear-gradient(
        100.84deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 100%
    );

    --gradient-pokeball: linear-gradient(180deg, #f5f5f5 50%, #ffffff 94.81%);
    --gradient-pokeball-grey: linear-gradient(135deg, #ececec 0%, #f5f5f5 100%);
    --gradient-pokeball-white: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    --gradient-pokemon-name: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 76.04%
    );
    --gradient-pokemon-circle: linear-gradient(
        334.58deg,
        rgba(255, 255, 255, 0.35) 16.24%,
        rgba(255, 255, 255, 0) 44.6%
    );

    /* Typeography */
    --title: 100px;
    --title-weight: 700;
    --application-title: 32px;
    --application-title-weight: 700;
    --pokemon-name: 26px;
    --pokemon-name-weight: 700;
    --filter-title: 16px;
    --filter-title-weight: 700;
    --description: 16px;
    --description-weight: 400;
    --pokemon-number: 16px;
    --pokemon-number-weight: 700;
    --pokemon-type: 12px;
    --pokemon-type-weight: 500;

    --text-white: #ffffff;
    --text-black: #17171b;
    --text-grey: #747476;
    --text-number: rgba(23, 23, 27, 0.6);
}
body {
    font-family: 'open-sans', sans-serif;
    overflow-x: hidden;
}

@media(max-width: 780px){

    .search-bar-wrapper {
        width: 100%;
    }
    .mainSearchBar {
        width: 100%;
    }
}

.all-pokemon-button {
    background-color: rgb(185, 56, 56);
    padding: 15px 30px 15px 30px;
    color: white;
    border-radius: 10px;
    border: none;
    font-weight: bold;
}

.all-pokemon-button:hover {
    background-color: rgb(135, 31, 31) ;
    transition: ease-in-out .2s;
}


.mainSearchBar {
    border-radius: 10px;
    width: 90%;
    height: 40px;
    border: 1px solid rgb(223, 223, 223);
    display: flex;
    margin: 0 auto;
    padding-left: 15px;
}

.mainSearchBar:focus {
    outline: none;
}

.searchResults {
    height: 500px;
    overflow-y: auto;
    margin: 0 auto;
    margin-top: 15px;
}

.pokemon-id {
    font-size: var(--pokemon-number);
    font-weight: var(--pokemon-number-weight);
    margin-bottom: 0;
    color: var(--text-black);
}

.pokemon-name {
    font-size: var(--pokemon-name);
    font-weight: var(--pokemon-name-weight);
    color: var(--text-white);
    margin-bottom: 0;
}

.pokemon-image {
    width: 130px;
    height: 130px;
}

.homepage-pokemon-card {
    height: 115px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding-left: 20px;
    background-image: url('png/6x3.svg'), url(png/pokeball.svg);
    background-repeat: no-repeat, no-repeat;
    background-size: 72px, 145px;
    background-position-x: 94px, 270px;
}

.homepage-pokemon-card__image {
    margin-left: 30px;
}
.homepage-pokemon-card__id {
    color: #17171b99;
    font-size: var(--pokemon-number);
    font-weight: var(--pokemon-number-weight);

    padding-top: 20px;
}

.homepage-pokemon-card__name {
    color: #ffffff;
    font-size: var(--pokemon-name);
    font-weight: var(--pokemon-name-weight);
}

.homepage-pokemon-card__type {
    width: 70px;
    height: 25px;
    border-radius: 4px;
    font-size: var(--pokemon-type);
    font-weight: var(--pokemon-type-weight);
    display: flex;
    justify-content: space-evenly;
    color: var(--text-white);
    margin-right: 5px;
}

.homepage-pokemon-card__type p,
.type-icon {
    margin-top: 5px;
    margin-bottom: 5px;
}

.type-container,
.pokemon-page-header {
    display: flex;
}

.pokemon-page-header {
    z-index: 1;
}

.type-icon {
    height: 15px;
}

.pokemon-page-header__details {
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 80px;
}

.header-background-text__container {
    z-index: -1;
}

.header-background-text {
    font-size: var(--title);
    font-weight: var(--title-weight);
    opacity: 50%;
}

.type-name-container {
    margin-top: 25px;
}

.stats-stat-name {
    margin: 0;
}

.stats-stat-level {
    background-color: var(--water-type);
    color: white;
    text-align: right;
    min-width: 25px;
    padding-right: 5px;
}

.stats-stat-max {
    width: 255px;
    background-color: rgb(212, 212, 212);
}

.stats-container {
    text-align: left;
    margin-bottom: 10px;
}

.pokemon-page-tab__container {
    display: flex;
    justify-content: space-around;
    margin-top: -35px;
    z-index: 5;
}

.pokemon-page-tab h1 {
    font-size: var(--filter-title);
    font-weight: var(--filter-title-weight);
    text-align: center;
    color: var(--text-white);
}

.pokemon-page-tab:hover {
    cursor: pointer;
}

.tab {
    display: none;
}

.current-tab {
    display: block;
}

.about-tab-marker,
.stats-tab-marker,
.evo-tab-marker {
    height: 5px;
    width: 5px;
    border-radius: 5px;
    background-color: white;
    margin-top: -5px;
}

.hidden {
    display: none;
}

.about-tab-marker {
    margin-left: 22px;
}

.stats-tab-marker {
    margin-left: 17px;
}

.evo-tab-marker {
    margin-left: 30px;
}

.tab-content {
    padding: 30px;
}

.tab-content-text {
    text-align: center;
}

.tab-content p {
    font-size: var(--description);
    font-weight: var(--description-weight);
    margin: 0 auto;
}

.tab-content h2 {
    font-size: var(--description);
    font-weight: var(--application-title-weight);
    margin-top: 30px;
    margin-bottom: 15px;
}

.species-data-title {
    font-size: var(--description);
    font-weight: var(--title-weight);
    color: var(--text-black);
    margin-bottom: 0px;
    width: 80px;
}

.species-data {
    color: var(--text-grey);
}

.pokedex-data-container {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
}

.ev-table {
    margin-bottom: 5px;
}

.ev-table-container {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;
}

.ev-table-data {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.sprites_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.sprites_wrapper a {
    text-decoration: none;
    color: var(--text-black);
}

.sprite_image {
    max-width: 150px;
}

.sprite_image{
    background-color: rgb(223, 223, 223);
    border-radius: 50%;
    border: 5px solid rgb(206, 206, 206);
}

.sprite_container {
    padding-top: 30px;
}

.center-container__stats {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media (min-width: 768px) {

    .search-bar-wrapper {
        width: 50%;
        margin: 0 auto;
    }
}

.stat,
.ev {
    padding: 6px;
}

.data-last {
    margin-right: 0px;
}

.hidden-ability {
    font-size: 14px !important;
}

.hp {
    background-color: rgb(185, 56, 56);
    padding-left: 15px;
    padding-right: 15px;
}

.hp-ev {
    background-color: rgb(246, 190, 190);
}

.attk {
    background-color: rgb(255, 191, 73);
}

.attk-ev {
    background-color: rgb(255, 230, 183);
}

.def {
    background-color: rgb(255, 255, 118);
}

.def-ev {
    background-color: rgb(255, 255, 218);
}

.sp-attk {
    background-color: rgb(197, 255, 109);
}

.sp-attk-ev {
    background-color: rgb(220, 255, 168);
}

.sp-def {
    background-color: rgb(57, 119, 200);
}

.sp-def-ev {
    background-color: rgb(210, 225, 245);
}

.speed {
    background-color: rgb(113, 64, 160);
}

.speed-ev {
    background-color: rgb(229, 202, 255);
}

.normal {
    background-color: var(--normal-type);
}

.fire {
    background-color: var(--fire-type);
}

.water {
    background-color: var(--water-type);
}

.electric {
    background-color: var(--electric-type);
}
.grass {
    background-color: var(--grass-type);
}
.ice {
    background-color: var(--ice-type);
}
.fighting {
    background-color: var(--fighting-type);
}
.poison {
    background-color: var(--poison-type);
}
.ground {
    background-color: var(--ground-type);
}
.flying {
    background-color: var(--flying-type);
}
.psychic {
    background-color: var(--psychic-type);
}
.bug {
    background-color: var(--bug-type);
}
.rock {
    background-color: var(--rock-type);
}
.ghost {
    background-color: var(--ghost-type);
}
.dragon {
    background-color: var(--dragon-type);
}
.dark {
    background-color: var(--dark-type);
}
.steel {
    background-color: var(--steel-type);
}
.fairy {
    background-color: var(--fairy-type);
}

.background-normal {
    background-color: var(--background-normal-type);
}

.background-fire {
    background-color: var(--background-fire-type);
}

.background-water {
    background-color: var(--background-water-type);
}

.background-electric {
    background-color: var(--background-electric-type);
}
.background-grass {
    background-color: var(--background-grass-type);
}
.background-ice {
    background-color: var(--background-ice-type);
}
.background-fighting {
    background-color: var(--background-fighting-type);
}
.background-poison {
    background-color: var(--background-poison-type);
}
.background-ground {
    background-color: var(--background-ground-type);
}
.background-flying {
    background-color: var(--background-flying-type);
}
.background-psychic {
    background-color: var(--background-psychic-type);
}
.background-bug {
    background-color: var(--background-bug-type);
}
.background-rock {
    background-color: var(--background-rock-type);
}
.background-ghost {
    background-color: var(--background-ghost-type);
}
.background-dragon {
    background-color: var(--background-dragon-type);
}
.background-dark {
    background-color: var(--background-dark-type);
}
.background-steel {
    background-color: var(--background-steel-type);
}
.background-fairy {
    background-color: var(--background-fairy-type);
}

.normal-text {
    color: var(--normal-type);
}

.fire-text {
    color: var(--fire-type);
}

.water-text {
    color: var(--water-type);
}

.electric-text {
    color: var(--electric-type);
}
.grass-text {
    color: var(--grass-type);
}
.ice-text {
    color: var(--ice-type);
}
.fighting-text {
    color: var(--fighting-type);
}
.poison-text {
    color: var(--poison-type);
}
.ground-text {
    color: var(--ground-type);
}
.flying-text {
    color: var(--flying-type);
}
.psychic-text {
    color: var(--psychic-type);
}
.bug-text {
    color: var(--bug-type);
}
.rock-text {
    color: var(--rock-type);
}
.ghost-text {
    color: var(--ghost-type);
}
.dragon-text {
    color: var(--dragon-type);
}
.dark-text {
    color: var(--dark-type);
}
.steel-text {
    color: var(--steel-type);
}
.fairy-text {
    color: var(--fairy-type);
}
